import React, { useContext, useEffect, useRef } from 'react'
import Breadcrumb from '../../components/Breadcrumb/'
import Questions from '../../components/quiz/Questions'
import StartSession from '../../components/quiz/StartSession'
import { StateContext } from '../../Providers/stateProvider'
import axios from 'axios'
import getUserFingerprint from '../../utils/userFingerprint'
import quizTypes from '../../Providers/reducers/quiz/type';
// import Ads468x60 from '../../components/Helmet/Adsterra/468x60'

import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>Lootloot Quiz - Test Your Knowledge and Win Rewards!</title>
    <meta name="description" content="Take part in Lootloot's exciting quizzes. Test your knowledge across various categories and stand a chance to win amazing rewards!" />
    <meta name="keywords" content="Lootloot, quiz, knowledge test, online quiz, rewards, win prizes" />
    <meta property="og:title" content="Lootloot Quiz - Test Your Knowledge and Win Rewards!" />
    <meta property="og:description" content="Take part in Lootloot's exciting quizzes. Test your knowledge across various categories and stand a chance to win amazing rewards!" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);


const Quiz = () => {
  const { quiz: { startQuiz, loading, question, endTime, categories }, dispatch } = useContext(StateContext)

  const fetchCategories = async () => {
    try {
      const fingerprint = await getUserFingerprint();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/quiz/categories`, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })
      dispatch({
        type: quizTypes.FETCH_CATEGORIES,
        payload: response.data
      })
    } catch (error) {
      dispatch({
        type: 'TOGGLE_NOTIFICATION',
        payload: {
          showNotification: true,
          message: error.message,
          type: 'error'
        }
      })
    }
  }

  const adRef = useRef(null);

  useEffect(() => {
    // First script
    const script1 = document.createElement('script');
    script1.innerHTML = `
      atOptions = {
        'key' : '35548e6b338a011ea62637792003eafe',
        'format' : 'iframe',
        'height' : 250,
        'width' : 300,
        'params' : {}
      };
    `;
    adRef.current.appendChild(script1);

    // Second script
    const script2 = document.createElement('script');
    script2.src = "//www.topcreativeformat.com/35548e6b338a011ea62637792003eafe/invoke.js";
    script2.async = true;
    adRef.current.appendChild(script2);

    // Cleanup function
    const currentAdRef = adRef.current; // Store the current ref value

    return () => {
      currentAdRef?.removeChild(script1);
      currentAdRef?.removeChild(script2);
    };
  }, []);

  const submitBeginSession = async (e, data) => {
    try {

      e.preventDefault();
      dispatch({
        type: quizTypes.START_QUIZ_SESSION
      })
      const fingerprint = await getUserFingerprint();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/quiz/start`, {
        ...data
      }, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      })

      if (response.data.status) {
        dispatch({
          type: quizTypes.START_QUIZ_SESSION_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: quizTypes.START_QUIZ_SESSION_FAIL,
          payload: { error: '' }
        })
        dispatch({
          type: 'TOGGLE_NOTIFICATION',
          payload: { message: response.data.message, showNotification: true }
        });
      }
    } catch (error) {
      console.log(error.response)
      dispatch({
        type: 'TOGGLE_NOTIFICATION',
        payload: { message: error?.response?.data?.message || 'Some error occurred pla try again later.', showNotification: true, type: 'error' }
      });
      dispatch({
        type: quizTypes.START_QUIZ_SESSION_FAIL,
        payload: { error: '' }
      })
    }

  }

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // function triggerAt(dateTime, callback = () => { }) {
  //   const now = new Date().getTime();
  //   const triggerTime = new Date(dateTime).getTime();
  //   const delay = triggerTime - now;

  //   if (delay > 0) {
  //     setTimeout(callback, delay);
  //   } else {
  //     console.warn("The specified time is in the past.");
  //   }
  // }

  // useEffect(() => {
  //   triggerAt(endTime, () => {
  //     console.log("Time's up!");
  //     // move to results page or show error page...
  // })
  // }, [endTime])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // User has left the page
        const leaveTime = new Date().getTime();
        localStorage.setItem('quizLeaveTime', leaveTime);
      } else {
        // User has returned to the page
        const returnTime = new Date().getTime();
        const leaveTime = localStorage.getItem('quizLeaveTime');
        
        if (leaveTime) {
          const timeAway = returnTime - parseInt(leaveTime);
          const maxTimeAway = 5 * 60 * 1000; // 5 minutes in milliseconds
          
          if (timeAway > maxTimeAway) {
            // Redirect to home page if user was away for more than 5 minutes
            window.location.href = '/'; // Adjust this if your home route is different
          }
          
          localStorage.removeItem('quizLeaveTime');
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <SEO />
      <Breadcrumb page={'Quiz'} />
      <div className='pb-120 mt-minus-300' ref={adRef}></div>
      {startQuiz ?
        <Questions
          question={question}
          endTime={endTime}
        />
        :
        <StartSession
          loading={loading}
          categories={categories}
          submitBeginSession={submitBeginSession}
        />}
    </>
  )
}

export default Quiz
