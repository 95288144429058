import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import axios from 'axios';
import getUserFingerprint from '../../utils/userFingerprint';

import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>Contact Lootloot - Get in Touch with Us</title>
    <meta name="description" content="Have questions or feedback? Contact Lootloot, your favorite Nigerian rewards platform. We're here to help you with any inquiries about our quizzes, games, and exciting rewards!" />
    <meta name="keywords" content="Lootloot, contact us, customer support, feedback, Nigerian rewards platform" />
    <meta property="og:title" content="Contact Lootloot - Get in Touch with Us" />
    <meta property="og:description" content="Have questions or feedback? Contact Lootloot, your favorite Nigerian rewards platform. We're here to help you with any inquiries about our quizzes, games, and exciting rewards!" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const fingerprint = await getUserFingerprint();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData, {
        headers: {
          fingerprint: fingerprint.fingerprintHash,
          location: JSON.stringify(fingerprint.location),
          fingerprintData: JSON.stringify(fingerprint.fingerprintData)
        }
      });

      if (response.data.status) {
        setSubmitMessage('Thank you for your message. We will get back to you soon!');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        setSubmitMessage('There was an error sending your message. Please try again.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SEO />
      <Breadcrumb page="Contact Us" style={{ paddingBottom: '100px' }} />
      <section className="contact-section pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header text-center">
                <h2 className="section-title">Get In Touch</h2>
                <p>We'd love to hear from you! Please fill out the form below and we'll get back to you as soon as possible.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <form onSubmit={handleSubmit} className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="cmn-btn" disabled={isSubmitting}>
                      {isSubmitting ? 'Sending...' : 'Send Message'}
                    </button>
                  </div>
                </div>
              </form>
              {submitMessage && (
                <div className="mt-4 text-center">
                  <p className={submitMessage.includes('error') ? 'text-danger' : 'text-success'}>
                    {submitMessage}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
