
import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>LootLoot Terms and Conditions - Play Responsibly</title>
    <meta name="description" content="Read our Terms and Conditions to understand the rules for using the LootLoot app. Play responsibly." />
    <meta name="keywords" content="LootLoot, terms and conditions, user agreement, Nigerian rewards platform" />
    <meta property="og:title" content="LootLoot Terms and Conditions - Play Responsibly" />
    <meta property="og:description" content="Read our Terms and Conditions to understand the rules for using the LootLoot app. Play responsibly." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);




const TC = () => {
  return (
    <>
      <SEO />
      <Breadcrumb page="Terms and Conditions" style={{ paddingBottom: '100px' }} />
      <section className="">
        <div className="container">
          <div className="row justify-content-center">
          <div id="container-975a220f0649f4fa0cf3815fad8d616e"></div>

            <div className="col-lg-10">
              <div className="terms-content">
                <h2 className="mb-4">Terms and Conditions</h2>
                <h4 className="mt-5 mb-3">1. Introduction</h4>
                <p>Welcome to lootloot! By using our app, you agree to comply with and be bound by the following terms and conditions. If you disagree with these terms, please refrain from using the app.</p>

                <h4 className="mt-5 mb-3">2. Eligibility</h4>
                <p>You must be 18 years or older to use the Lootloot app. By registering or using the app, you confirm that you meet this requirement.</p>

                <h4 className="mt-5 mb-3">3. Use of the App</h4>
                <p>The lootloot app is designed for entertainment, allowing users to participate in games, quizzes, and other activities to earn rewards. Users must not exploit or manipulate the app in any way that can cause harm or unfair advantage over others.</p>

                <ul style={{ paddingLeft: '20px' }}>
                  <li style={{ marginBottom: '15px' }}>
                    <h5 className="mt-4 mb-2">User Accounts</h5>
                    <p>To access certain features, you may be required to create an account. You are responsible for maintaining the confidentiality of your login credentials.</p>
                    <p>You agree to provide accurate, current, and complete information during registration and to update your information as necessary.</p>
                  </li>
                </ul>

                <h4 className="mt-5 mb-3">4. Rewards</h4>
                <p>LootLoot allows users to participate in quizzes, games, and other activities to earn rewards such as data plans, recharge card pins, TV subscriptions, and electric recharge pins.</p>
                <p>Rewards are subject to availability and may be delivered electronically via email or SMS to the contact details you provide.</p>
                <p>The rewards are non-transferable, and LootLoot reserves the right to modify or discontinue rewards at any time without notice.</p>

                <h4 className="mt-5 mb-3">5. User Conduct</h4>
                <p>Users must lawfully use the app and avoid:</p>
                <ul style={{ paddingLeft: '20px' }}>
                  <li style={{ marginBottom: '15px' }}>Manipulating results, quizzes, or games.</li>
                  <li style={{ marginBottom: '15px' }}>Sharing inappropriate or offensive content.</li>
                  <li style={{ marginBottom: '15px' }}>Spamming or harassing other users.</li>
                  <li style={{ marginBottom: '15px' }}>You agree not to engage in any activity that may harm or disrupt the LootLoot platform or other users.</li>
                  <li style={{ marginBottom: '15px' }}>Cheating, using bots, or manipulating results to gain rewards unfairly is prohibited. LootLoot reserves the right to suspend or terminate any account found in violation of this rule.</li>
                  <li style={{ marginBottom: '15px' }}>Failure to comply may lead to suspension or termination of your account.</li>
                </ul>

                <h4 className="mt-5 mb-3">6. Account Termination</h4>
                <p>lootloot reserves the right to suspend or terminate user accounts without notice for any violation of these terms.</p>

                <h4 className="mt-5 mb-3">7. Liability</h4>
                <p>We are not responsible for any loss or damage arising from the use of the app or failure to receive a reward.</p>

                <h4 className="mt-5 mb-3">8. Changes to Terms</h4>
                <p>lootloot reserves the right to modify these terms at any time. Users will be notified of any changes and continued use of the app constitutes acceptance of the revised terms.</p>

                <h4 className="mt-5 mb-3">9. Intellectual Property</h4>
                <p>All content, trademarks, logos, and other intellectual property rights on the LootLoot platform are owned by or licensed to LootLoot. You may not use, reproduce, or distribute any of this content without prior written consent.</p>

                <h4 className="mt-5 mb-3">10. Termination</h4>
                <p>LootLoot reserves the right to terminate or suspend your account at any time if you violate these Terms or for any reason at its sole discretion.</p>

                <h4 className="mt-5 mb-3">11. Governing Law</h4>
                <p>These Terms are governed by and construed under the laws of the Federal Republic of Nigeria.</p>

                <h4 className="mt-5 mb-3">12. Changes to Terms</h4>
                <p>LootLoot may modify these Terms at any time. Continued use of the platform following such changes indicates your acceptance of the updated terms.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TC;
