import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import Swal from "sweetalert2";
import UserInfo from "../UserPanel/UserInfo";
import Menu from "../UserPanel/Menu";
import { useContext } from "react";
import { StateContext } from "../../Providers/stateProvider";

const PrivateRoutes = () => {
  const { user: { data} } = useContext(StateContext)
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      title: 'Session timeout',
      icon: 'error',
      text: 'Please login to continue.',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true,
    })
    return <Navigate to="/login" replace />;
  }

  return <>
    <div className="inner-hero-section style--five">
    </div>
    <div className="mt-minus-150 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <UserInfo user={data?.data} />
            <Menu />
          </div>
          <Outlet />

        </div>
      </div>
    </div>
  </>;
}

export default PrivateRoutes;
