import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer-section">
    <div className="bg-shape--top"><img src="/assets/images/elements/round-shape-2.png" alt="shape" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">
          {/* <div className="subscribe-area"> */}
            {/* <div className="left">
              <span className="subtitle">Subscribe to Sorko</span>
              <h3 className="title">To Get Exclusive Benefits</h3>
            </div> */}
            {/* <div className="right">
              <form className="subscribe-form">
                <input type="email" name="subscribe_email" id="subscribe_email" placeholder="Enter Your Email"/>
                <button type="submit">Subscribe</button>
              </form>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
    <div className="container pt-120">
      <div className="row pb-5 align-items-center">
        <div className="col-lg-4">
          <ul className="app-btn">
            {/* <li><Link to="#0"><img src="/assets/images/icon/store-btn/1.png" alt="image" /></Link></li>
            <li><Link to="#0"><img src="/assets/images/icon/store-btn/2.png" alt="image" /></Link></li> */}
          </ul>
        </div>
        <div className="col-lg-8">
          <ul className="short-links justify-content-lg-end justify-content-center">
            <li><Link to="/about">About</Link></li>
            <li><Link to="/FAQ">FAQs</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/terms-and-conditions">Terms and conditions</Link></li>
            <li><Link to="/privacy-policy">Privacy policy</Link></li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="row py-5 align-items-center">
        <div className="col-lg-6">
          <p className="copy-right-text text-lg-start text-center mb-lg-0 mb-3">Copyright © <Link to="index-2.html">Twoleaved Gate</Link></p>
        </div>
        <div className="col-lg-6">
          <ul className="social-links justify-content-lg-end justify-content-center">
            <li><Link to="#0"><i className="fab fa-facebook-f"></i></Link></li>
            <li><Link to="#0"><i className="fab fa-twitter"></i></Link></li>r
            <li><Link to="#0"><i className="fab fa-linkedin-in"></i></Link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer
