import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import './faq.css'; // Import the CSS file

import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>LootLoot FAQ - Your Questions Answered</title>
    <meta name="description" content="Find answers to frequently asked questions about LootLoot, Nigeria's premier rewards platform. Learn how to earn and redeem rewards, use the app, and more!" />
    <meta name="keywords" content="LootLoot, FAQ, frequently asked questions, rewards, Nigerian app, quizzes, games" />
    <meta property="og:title" content="LootLoot FAQ - Your Questions Answered" />
    <meta property="og:description" content="Find answers to frequently asked questions about LootLoot, Nigeria's premier rewards platform. Learn how to earn and redeem rewards, use the app, and more!" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);


const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is LootLoot?",
      answer: "LootLoot is a Nigerian-based platform where users can play games, take quizzes, and participate in various activities to earn rewards such as data plans, recharge card pins, TV subscriptions, and electric recharge pins."
    },
    {
      question: "How do I earn rewards on LootLoot?",
      answer: "You can earn rewards by participating in quizzes, games, and other activities available on the app. The more you engage, the more opportunities you have to earn rewards."
    },
    {
      question: "Is LootLoot free to use?",
      answer: "Yes, LootLoot is free to use. However, some features or activities might require login/registration."
    },
    {
      question: "How do I redeem my rewards?",
      answer: "You can redeem your rewards by clicking on the 'Redeem' button on the rewards page. You will need to provide your bank details to receive your rewards."
    },
    {
      question: "What are the terms and conditions for using LootLoot?",
      answer: "Please refer to our Terms and Conditions page for detailed information on the terms and conditions for using LootLoot."
    },
    {
      question: "How can I contact LootLoot?",
      answer: "You can contact LootLoot by visiting our contact page or sending an email to support@lootloot.com."
    },
    {
      question: "What are the benefits of using LootLoot?",
      answer: "Using LootLoot can help you earn rewards for participating in activities you enjoy. It can also help you stay entertained and engaged with new and exciting content."
    },
    {
      question: "Is my personal information safe on LootLoot?",
      answer: "Yes, we take user privacy very seriously. We use industry-standard security measures to protect your personal information. For more details, please refer to our Privacy Policy."
    },
    {
      question: "Can I use LootLoot on my mobile device?",
      answer: "Yes, LootLoot is designed to be mobile-friendly. You can access it through your mobile browser or download our app from the App Store or Google Play Store."
    },
    {
      question: "How often are new quizzes or games added?",
      answer: "We regularly update our content to keep things fresh and exciting. New quizzes and games are typically added on a weekly basis."
    },
    {
      question: "What should I do if I encounter a technical issue?",
      answer: "If you encounter any technical issues, please try refreshing the page or restarting the app. If the problem persists, contact our support team with details of the issue."
    },
    {
      question: "Are there any age restrictions for using LootLoot?",
      answer: "Yes, users must be at least 18 years old to use LootLoot. This is to ensure compliance with legal regulations regarding online rewards and gaming."
    },
    {
      question: "Can I invite my friends to join LootLoot?",
      answer: "Absolutely! We encourage users to invite their friends. In fact, we often have referral programs where you can earn extra rewards for bringing new users to the platform."
    }
  ];

  return (
    <>
      <SEO />
      <Breadcrumb page="FAQ" style={{ paddingBottom: '100px' }} />
      <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div id="container-975a220f0649f4fa0cf3815fad8d616e"></div>
            <div className="col-lg-10">
              <div className="faq-content">
                <h2 className="mb-4">Frequently Asked Questions</h2>

                {faqItems.map((item, index) => (
                  <div key={index} className="faq-item accordion">
                    <div
                      className="accordion-header"
                      onClick={() => toggleAccordion(index)}
                    >
                      <h4 className="">{item.question}</h4>
                      <span className="accordion-icon">
                        {activeIndex === index ? '-' : '+'}
                      </span>
                    </div>
                    <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
                      <p>{item.answer}</p>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
