import { useEffect, useState, useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Routes } from "./navigation/routes";
import { StateContext } from "./Providers/stateProvider";
import PreLoader from "./components/PreLoader";
import axios from "axios";

const routes = createBrowserRouter([...Routes]);

function App() {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [latestVersion, setLatestVersion] = useState(null);
  const { loading } = useContext(StateContext)

  const checkForUpdates = async () => {
    try {
      const storedVersion = localStorage.getItem('appVersion');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/version?version=${storedVersion || '1.0'}`);
      const new_version = response.data.version;
      const is_up_to_date = response.data.is_up_to_date;
      console.log('new_version', new_version, 'is_up_to_date', is_up_to_date, storedVersion)

      if (storedVersion) {
        if (is_up_to_date && new_version !== storedVersion) {
          setNewVersionAvailable(true);
          setLatestVersion(new_version);
        }
      } else {
        localStorage.setItem('appVersion', new_version);
      }
    } catch (error) {
      console.error("Failed to check for updates:", error);
    }
  };

  useEffect(() => {
    checkForUpdates(); // this check needs to be triggered by something other than page load
    
  }, []);

  const handleUpdate = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
    window.location.reload(true);
    if (latestVersion) {
      localStorage.setItem('appVersion', latestVersion);
    }
  };

  return (
    <>
      {newVersionAvailable && (
        <div style={{
          position: 'fixed',
          top: '10px',
          right: '10px',
          backgroundColor: 'rgb(16, 8, 56)',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 9999,
          border: '1px solid #490758'
        }}>
          <p>A new version is available!</p>
          <button style={{ background: 'none', color: '#ffb200', border: 'none', cursor: 'pointer' }} onClick={handleUpdate}>Update Now</button>
        </div>
      )}
      {loading && <PreLoader />}
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
