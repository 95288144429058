import React, { useContext, useEffect } from 'react'
import axios from 'axios';
import Hero from '../../components/Hero';
import Contest from '../../components/Contest';
import Jackpot from '../../components/Jackpot';
import { StateContext } from '../../Providers/stateProvider'
import appTypes from '../../Providers/reducers/app/type';
import getUserFingerprint from '../../utils/userFingerprint';
import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>Lootloot - Take a Chance and Get Recharged!</title>
    <meta name="description" content="Join Lootloot for exciting quizzes and activities. Take a chance on your mind and get recharged. You might be our next lucky winner!" />
    <meta name="keywords" content="Lootloot, quiz, contest, jackpot, rewards" />
    <meta property="og:title" content="Lootloot - Take a Chance and Get Recharged!" />
    <meta property="og:description" content="Join Lootloot for exciting quizzes and activities. Take a chance on your mind and get recharged. You might be our next lucky winner!" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);


const HomePage = () => {
  const { app: { fastestFingers: { timerStarted, pin } }, dispatch } = useContext(StateContext)

  const getFastestFingerPin = async () => {
    const fingerprint = await getUserFingerprint()
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/app/fastest-fingers`, {
          headers: {
            fingerprint: fingerprint.fingerprintHash,
            location: JSON.stringify(fingerprint.location),
            fingerprintData: JSON.stringify(fingerprint.fingerprintData)
          }
        });

        dispatch({
          type: appTypes.FETCH_FASTEST_FINGER_PIN_SUCCESS,
          payload: response.data
        })
      } catch (error) {
        dispatch({
          type: appTypes.FETCH_FASTEST_FINGER_PIN_FAIL,
          payload: error.message
        })
      }
    }

  const handleCountdownEnd = async () => {
    if (timerStarted && pin === '') {
      await getFastestFingerPin();
    }
  }

  useEffect(() => {
    getFastestFingerPin()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // console.log('I render...')

  return (
    <>
      <SEO />
      <Hero />
      <div id="container-975a220f0649f4fa0cf3815fad8d616e"></div>
      <Contest handleCountdownEnd={handleCountdownEnd} />
      <Jackpot />
    </>
  )
}

export default HomePage
