import React from 'react'
import Breadcrumb from '../../components/Breadcrumb'
// import ComingSoon from '../../components/ComingSoon'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>Lootloot Activities - Engage and Earn Rewards!</title>
    <meta name="description" content="Participate in Lootloot's exciting activities. Click buttons, share suggestions, and engage with our app to earn rewards and reduce wait times!" />
    <meta name="keywords" content="Lootloot, activities, rewards, engagement, app feedback" />
    <meta property="og:title" content="Lootloot Activities - Engage and Earn Rewards!" />
    <meta property="og:description" content="Participate in Lootloot's exciting activities. Click buttons, share suggestions, and engage with our app to earn rewards and reduce wait times!" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);

const ActivityComponent = () => {
  const navigate = useNavigate()
  const activity = [
    {
      name: 'Click the Button',
      link: '/click-the-button',
      text: 'Click the botton a number times to reduce your wait time.'
    },
    {
      name: 'Suggestion Box',
      link: '#',
      text: `Tell us something you would like to see in the app, or something you like or don't like about the app`
    },
  ]
  return (
    <>
      <SEO />
      <Breadcrumb page={'Activity'} />
      <div className="container">
        <div className="referral-overview mt-30 pb-120 mt-minus-300">
          <div id="adm-container-29961"></div>
          <h4 className="contest-wrapper__title" style={{ fontSize: '40px' }}>Chose one, any one!</h4>
          <div className="row justify-content-center mb-none-30">
            {activity.map(a => (
              <div onClick={() => navigate(a.link)} style={{ cursor: 'pointer' }} className="col-lg-5 col-sm-6 mb-30">
                <div className="referral-crad">
                  <div className="referral-crad__icon">
                    <img src="/assets/images/icon/referral/1.png" alt="ss" /></div>
                  <div className="referral-crad__content">
                    <h3 className="number">{a.name}</h3>
                    <span>{a.text}</span>
                  </div>
                </div>
              </div>
            ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ActivityComponent
