import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>LootLoot Privacy Policy - Your Data is Safe with Us</title>
    <meta name="description" content="Read our Privacy Policy to understand how we handle your data at LootLoot. Your privacy is our priority." />
    <meta name="keywords" content="LootLoot, privacy policy, data protection, user data, Nigerian rewards platform" />
    <meta property="og:title" content="LootLoot Privacy Policy - Your Data is Safe with Us" />
    <meta property="og:description" content="Read our Privacy Policy to understand how we handle your data at LootLoot. Your privacy is our priority." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);

const Privacy = () => {
  return (
    <>
      <SEO />
      <Breadcrumb page="Privacy Policy" style={{ paddingBottom: '100px' }} />
      <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div id="container-975a220f0649f4fa0cf3815fad8d616e"></div>
            <div className="col-lg-10">
              <div className="privacy-content">
                <h2 className="mb-4">Privacy Policy</h2>

                <h4 className="mt-5 mb-3">Introduction</h4>
                <p>At LootLoot, your privacy is our priority. This Privacy Policy explains how we collect, use, and protect your information when you use our app.</p>

                <h4 className="mt-5 mb-3">Information We Collect</h4>
                <ul style={{ paddingLeft: '20px' }}>
                  <li style={{ marginBottom: '15px' }}>
                    <strong>Personal Information:</strong> When you sign up or use the LootLoot app, we may collect personal information such as your name, phone number, email address, and other necessary information to provide our services.
                  </li>
                  <li style={{ marginBottom: '15px' }}>
                    <strong>Usage Data:</strong> We collect information on how you interact with the app, including pages viewed, time spent, and actions performed, such as participating in quizzes or games.
                  </li>
                </ul>

                <h4 className="mt-5 mb-3">How We Use Your Information</h4>
                <ul style={{ paddingLeft: '20px' }}>
                  <li style={{ marginBottom: '15px' }}>To provide, maintain, and improve the LootLoot app and its services.</li>
                  <li style={{ marginBottom: '15px' }}>To communicate with you, such as sending reward notifications, updates, and marketing messages.</li>
                  <li style={{ marginBottom: '15px' }}>To ensure compliance with our Terms and monitor fraudulent activity.</li>
                </ul>

                <h4 className="mt-5 mb-3">Sharing of Information</h4>
                <p>We do not sell or rent your personal information to third parties. However, we may share your information:</p>
                <ul style={{ paddingLeft: '20px' }}>
                  <li style={{ marginBottom: '15px' }}>With third-party service providers who assist us in operating the app.</li>
                  <li style={{ marginBottom: '15px' }}>If required by law or to protect our legal rights.</li>
                </ul>

                <h4 className="mt-5 mb-3">Data Security</h4>
                <p>LootLoot takes reasonable steps to protect your personal information from unauthorized access, misuse, or disclosure. However, no security measure is 100% foolproof.</p>

                <h4 className="mt-5 mb-3">Cookies and Tracking</h4>
                <p>We may use cookies and similar tracking technologies to enhance your experience and track user activity on the app.</p>

                <h4 className="mt-5 mb-3">Third-Party Links</h4>
                <p>LootLoot may contain links to third-party websites. We are not responsible for the privacy practices of these external sites.</p>

                <h4 className="mt-5 mb-3">Your Choices</h4>
                <ul style={{ paddingLeft: '20px' }}>
                  <li style={{ marginBottom: '15px' }}>You can update or delete your account information at any time through the app.</li>
                  <li style={{ marginBottom: '15px' }}>You may opt out of receiving marketing communications by following the instructions provided in those messages.</li>
                </ul>

                <h4 className="mt-5 mb-3">Changes to This Privacy Policy</h4>
                <p>LootLoot may update this Privacy Policy from time to time. Any changes will be posted within the app, and your continued use of the app constitutes acceptance of those changes.</p>

                <h4 className="mt-5 mb-3">Contact Us</h4>
                <p>If you have any questions about this Privacy Policy, please contact us at [insert email/contact details].</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;

