import React from 'react'
import Breadcrumb from '../../components/Breadcrumb';
import { Helmet } from 'react-helmet-async';

const SEO = () => (
  <Helmet>
    <title>About Lootloot - Fun, Rewards, and Excitement!</title>
    <meta name="description" content="Learn about Lootloot, your ultimate Nigerian platform for fun, rewards, and excitement. Play games, take quizzes, and earn real rewards like data plans and recharge cards!" />
    <meta name="keywords" content="Lootloot, about us, rewards platform, Nigerian app, quizzes, games, data plans, recharge cards" />
    <meta property="og:title" content="About Lootloot - Fun, Rewards, and Excitement!" />
    <meta property="og:description" content="Learn about Lootloot, your ultimate Nigerian platform for fun, rewards, and excitement. Play games, take quizzes, and earn real rewards like data plans and recharge cards!" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:image" content="/assets/images/ll_logo2.png" />
    <link rel="canonical" href={window.location.href} />
  </Helmet>
);

const About = () => {
  return (
    <>
      <SEO />
      <Breadcrumb page="About Us" style={{ paddingBottom: '100px' }} />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">

            <section className="">
              <div className="container">
                <div className="row justify-content-center">
                  <div id="container-975a220f0649f4fa0cf3815fad8d616e"></div>
                  <div className="col-lg-10">
                    <div className="about-content">
                      <h2 className="mb-4">About Us</h2>
                      <p>Welcome to lootloot, your ultimate platform for fun, rewards, and excitement! At lootloot, we believe in turning entertainment into opportunities. Our users can play games, take quizzes, and participate in various activities to earn rewards such as data plans, recharge card pins, TV subscriptions, and electric recharge pins.</p>
                      <p>Our mission is to provide a unique and engaging experience that gives back to our users. We're proud to be a Nigerian-based platform, offering everyday rewards that matter to our community. Whether you're looking for a fun way to pass time, challenge your knowledge, or get rewarded for engaging with content, lootloot is the app for you!</p>
                      <p>Feel free to reach out to us anytime with questions or feedback. We're here to make your experience enjoyable and rewarding.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
