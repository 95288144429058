import React, { useEffect, useState } from 'react'
import './style.css'

const StartSession = ({ submitBeginSession, loading, categories }) => {
  const [form, setForm] = useState({
    category_id: '',
    network: '',
    phone_number: '',
    't&c': false
  })
  const [disabled, setDisabled] = useState(true)
  const network = ['mtn', 'airtel', 'glo', '9mobile']

  const handleChange = (target, value) => {
    setForm({
      ...form,
      [target]: value
    })
  }

  useEffect(() => {
    if (
      form.category_id !== '' &&
      form.network !== '' &&
      form.phone_number !== '' &&
      form['t&c'] !== false
    ) { setDisabled(false) } else  { setDisabled(true)}
  }, [form])

  return (
    <section id='start-session' 
    // className="pb-120 mt-minus-300"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 account-form-area" >
            <h3 className="title">Start a Quiz Session</h3>
            <div className="account-form-wrapper">
              <form onSubmit={(e) => submitBeginSession(e, form)}>
                {/* <div className="form-groupa"> */}
                  <label>Category <sup>*</sup></label>
                  <select id="cat" className='okay.lk' onChange={(e) => handleChange('category_id', e.target.value)}>
                    <option>- Chose a Category -</option>
                    {categories && categories.map(c => (
                      <option key={c.id} value={c.id}>{c.name}</option>
                    ))}
                  </select>
                {/* </div> */}

                <div className="form-group">
                  <label>Network <sup>*</sup></label>
                  <select onChange={(e) => handleChange('network', e.target.value)}>
                    <option>- Select your Network -</option>
                    {network.map(n => (
                      <option key={n} value={n}>{n.toUpperCase()}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Phone <sup>*</sup></label>
                  <input type="text" onChange={(e) => handleChange('phone_number', e.target.value)} name="phone" maxLength={11} minLength={11} id="phone" placeholder="Enter your phone number" />
                </div>

                <div className="d-flex flex-wrap mt-2">
                  <div className="custom-checkbox">
                    <input type="checkbox" onChange={(e) => handleChange('t&c', e.target.checked)} name="id-2" id="id-2" />
                    <label htmlFor="id-2">I agree to the</label>
                    <span className="checkbox"></span>
                  </div>
                  <a href="#0" className="link ml-1">Terms, Privacy Policy</a>
                </div>
                <div className="form-group text-center mt-5">
                  <button disabled={disabled || loading} className="cmn-btn">
                    { loading ? 'Loading...' : 'Begin'}
                  </button>
                </div>
              </form>
              {/* <p className="text-center mt-4"> Already have an account? <a href="#0" data-bs-toggle="modal" data-bs-target="#loginModal">Login</a></p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StartSession
